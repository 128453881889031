import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import {
	MAT_DIALOG_DATA,
	MatDialog,
	MatDialogRef,
} from '@angular/material/dialog'
import { ImageUpload } from '../../models/general.models'
import { ImageCropperComponent } from './image-cropper/image-cropper.component'

@Component({
	selector: 'app-upload-image',
	templateUrl: './upload-image.component.html',
	styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent implements OnInit {
	@ViewChild('fileInput', { static: false }) fileInput!: ElementRef
	acceptFiles = 'image/png, image/jpeg, image/jpg'
	imageChangedEvent: any = ''
	croppedImage: any = ''
	uploadedFile: any
	sizeError = false
	constructor(
		public dialog: MatDialog,
		public dialogRef: MatDialogRef<UploadImageComponent>,
		@Inject(MAT_DIALOG_DATA) public imageUpload: ImageUpload
	) {}

	ngOnInit(): void {}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event
		if (
			this.imageChangedEvent?.target?.files[0]?.size <= 1000000 &&
			this.acceptFiles.includes(this.imageChangedEvent.target.files[0].type)
		) {
			this.sizeError = false
			const dialogRef = this.dialog.open(ImageCropperComponent, {
				width: '500px',
				height: '550px',
				panelClass: ['overflow-y-auto'],
				data: {
					imageUpload: this.imageUpload,
					imageChangedEvent: this.imageChangedEvent,
				},
			})

			dialogRef.afterClosed().subscribe((result) => {
				if (result) {
					this.croppedImage = result
					this.fileRender()
				} else {
					this.resetFileInput()
					this.deleteImage()
				}
			})
		} else this.sizeError = true
	}
	resetFileInput() {
		this.fileInput.nativeElement.value = null
	}
	fileRender() {
		this.uploadedFile = new File(
			[this.dataURItoBlob(this.croppedImage)],
			'image.jpg'
		)
	}

	dataURItoBlob(dataURI: any): Blob {
		const byteString = atob(dataURI.split(',')[1])
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
		const ab = new ArrayBuffer(byteString.length)
		let ia = new Uint8Array(ab)
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i)
		}
		return new Blob([ab], { type: mimeString })
	}

	deleteImage() {
		this.croppedImage = ''
		this.imageChangedEvent = ''
	}
	closeDialog() {
		this.dialogRef.close()
	}
}
