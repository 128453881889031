<div class="mt-5 search flex items-center justify-start">
	<div
		*ngIf="showSearch"
		appearance="outline"
		class="mr-12px rtl:ml-12px w-full relative flex items-center border  rounded-[{{
			isRounded ? 50 : 6
		}}px] py-[13px] px-[12px] bg-white"
	>
		<!-- *ngIf="!searchVal.value" -->

		<img
			class="absolute rtl:right-[14px] ltr:left-[14px]"
			src="./assets/images/search-normal.svg"
			alt=""
			srcset=""
		/>
		<input
			[formControl]="searchVal"
			class="bg-transparent px-[40px] text-dark-grey w-full"
			matInput
			placeholder="{{ searchKey | translate }}"
			(keyup.enter)="keyWord.emit(searchVal.value)"
			(keyup)="inputChange($event)"
		/>

		<div class="flex absolute ltr:right-0 rtl:left-0" *ngIf="searchVal.value">
			<img
				class="inline-block cursor-pointer"
				src="./assets/images/close-square.svg"
				alt=""
				(click)="searchVal.setValue(''); keyWord.emit(searchVal.value)"
			/>
			<div
				class="h-[19px] w-[1px] border-r-[1px] border-secondry-color-Gray inline-block mx-12px max-mobile:mx-1"
			></div>
			<img
				(click)="keyWord.emit(searchVal.value)"
				class="rtl:ml-3 ltr:mr-3 inline-block cursor-pointer"
				src="./assets/images/search-gradient.svg"
				alt=""
			/>
		</div>
	</div>
	<button
		class="bg-gradient inline-flex rounded-[12px] items-center box-shadow {{
			filterCount ? 'ltr:pr-2 rtl :pl-2' : ''
		}} "
		*ngIf="showFilter"
		(click)="toggleFilter.emit()"
	>
		<img class="fill-[#FCFCFC] p-12px" src="assets/images/filter.svg" alt="" />
		<div
			*ngIf="filterCount"
			class="text-white inline-flex items-center ltr:pr-6 rtl:pl-6"
		>
			<span class="whitespace-nowrap">{{
				'ACTIONS.APPLY_NO_FILTER' | translate : { number: filterCount }
			}}</span>
			<button
				aria-label="Clear"
				type="reset"
				(click)="resetFilter.emit()"
				class="w-9 h-8 rounded-md flex items-center justify-center mx-0.5"
			>
				<svg width="24" height="25" viewBox="0 0 24 25" fill="none">
					<path
						d="M9.16999 16.318C8.97999 16.318 8.78999 16.248 8.63999 16.098C8.34999 15.808 8.34999 15.328 8.63999 15.038L14.3 9.37802C14.59 9.08802 15.07 9.08802 15.36 9.37802C15.65 9.66802 15.65 10.148 15.36 10.438L9.69998 16.098C9.55998 16.248 9.35999 16.318 9.16999 16.318Z"
						fill="#FCFCFC"
					/>
					<path
						d="M14.83 16.318C14.64 16.318 14.45 16.248 14.3 16.098L8.63999 10.438C8.34999 10.148 8.34999 9.66802 8.63999 9.37802C8.92999 9.08802 9.40998 9.08802 9.69998 9.37802L15.36 15.038C15.65 15.328 15.65 15.808 15.36 16.098C15.21 16.248 15.02 16.318 14.83 16.318Z"
						fill="#FCFCFC"
					/>
					<path
						d="M15 23.488H9C3.57 23.488 1.25 21.168 1.25 15.738V9.73804C1.25 4.30804 3.57 1.98804 9 1.98804H15C20.43 1.98804 22.75 4.30804 22.75 9.73804V15.738C22.75 21.168 20.43 23.488 15 23.488ZM9 3.48804C4.39 3.48804 2.75 5.12804 2.75 9.73804V15.738C2.75 20.348 4.39 21.988 9 21.988H15C19.61 21.988 21.25 20.348 21.25 15.738V9.73804C21.25 5.12804 19.61 3.48804 15 3.48804H9Z"
						fill="#FCFCFC"
					/>
				</svg>
			</button>
		</div>
	</button>
</div>
