import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatIconModule } from '@angular/material/icon'
import { MatSelectModule } from '@angular/material/select'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatMenuModule } from '@angular/material/menu'
import { MatTableModule } from '@angular/material/table'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatChipsModule } from '@angular/material/chips'
import { MatNativeDateModule } from '@angular/material/core'
import { MatOptionModule } from '@angular/material/core'
import { MatCardModule } from '@angular/material/card'
import { MatRadioModule } from '@angular/material/radio'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSliderModule } from '@angular/material/slider'
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker'
import { CdkAccordionModule } from '@angular/cdk/accordion'
import { MatTabsModule } from '@angular/material/tabs'

import { MatTreeModule } from '@angular/material/tree'

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		MatInputModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatIconModule,
		MatSelectModule,
		MatCheckboxModule,
		MatButtonModule,
		MatDialogModule,
		MatMenuModule,
		MatTableModule,
		MatSlideToggleModule,
		MatNativeDateModule,
		MatCardModule,
		MatChipsModule,
		MatRadioModule,
		NgxMaterialTimepickerModule.setOpts(
			localStorage.getItem('language') == 'ar' ? 'ar-AE' : 'en-US',
			'arab'
		),
		MatSidenavModule,
		MatAutocompleteModule,
		MatExpansionModule,
		MatSliderModule,
		MatOptionModule,
		CdkAccordionModule,
		MatTabsModule,
		MatTreeModule,
	],
	exports: [
		MatInputModule,
		MatFormFieldModule,
		MatDatepickerModule,
		MatIconModule,
		MatSelectModule,
		MatCheckboxModule,
		MatButtonModule,
		MatDialogModule,
		MatMenuModule,
		MatTableModule,
		MatSlideToggleModule,
		MatChipsModule,
		MatRadioModule,
		NgxMaterialTimepickerModule,
		MatSidenavModule,
		MatButtonToggleModule,
		MatAutocompleteModule,
		MatExpansionModule,
		MatSliderModule,
		MatOptionModule,
		CdkAccordionModule,
		MatTabsModule,
		MatCardModule,
		MatTreeModule,
	],
})
export class MaterialsModule {}
