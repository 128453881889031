const testFirebase = {
	apiKey: 'AIzaSyAUJQU-H1vYuzb3A1lbrpkh9xSKmTKCfRQ',
	authDomain: 'go-care-web-b1210.firebaseapp.com',
	projectId: 'go-care-web-b1210',
	storageBucket: 'go-care-web-b1210.appspot.com',
	messagingSenderId: '731688100542',
	appId: '1:731688100542:web:1f98c4f6b964e08595b4ef',
	measurementId: 'G-NHHM9C7JFR',
	keyPair:
		'BApDgfah5vVV6LiswrVe8lYKLGqqcawL0DPif9UxtJpRlpWAKD_-cRjZyTNIbDRGFlXGLz70Ie7f_mR87_QJb00',
	serverKey:
		'key=AAAAqlwCYr4:APA91bE9v2PFAV80hXCwa3nQ57XHcnGfVsjk7bStY3EQo0Q-thdUGa8K-hs7PB6JIkQQeen07QMK6kV7JgYTigmmWa4by_BgH4Hd5GljZAXBIV98bYthpbw_BI4ECfLE7LuNV5HQTqQZ',
}

export const environment = {
	production: false,
	baseURL: 'https://gocare-back-develop.salonspace1.com/',
	apiUrl: 'https://gocare-back-develop.salonspace1.com/api',
	attachmentPath: 'https://gocare-back-develop.salonspace1.com/Attachments',
	firebaseConfig: {
		...testFirebase,
	},
	googleMapKey: 'AIzaSyA7GuhrFYR_k6lQ1JQvClXX9SEdyg9BpvA',
}

// export const environment = {
// 	production: false,
// 	baseURL: 'https://gocare-admin-cashback.salonspace1.com/',
// 	apiUrl: 'https://gocare-development-cashback.salonspace1.com/api',
// 	attachmentPath:
// 		'https://gocare-development-payout.salonspace1.com/Attachments',
// 	firebaseConfig: { ...testFirebase },
// 	googleMapKey: 'AIzaSyA7GuhrFYR_k6lQ1JQvClXX9SEdyg9BpvA',
// }
