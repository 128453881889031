export interface confirmModel {
	image?: string
	title: string
	message: string
	confirmBtn: ''
	cancelBtn?: ''
	flex: boolean
	notflex: boolean
	confirmBtnStyle?: any
	cancelBtnStyle?: any
	msgStyle?: string
	hasInput: boolean
	label: string
	titleStyle?: string
	fullWidth?:boolean
}

export class Table {
	prefix: string = ''
	columns: any[] = []
	types: string[] = []
	data: any[] = []
	actions: string[] = []
	statusEnum: any[] = []
	totalCount: number = 0
	paging?: { pageNumber: number; pageSize: number } = {
		pageNumber: 1,
		pageSize: 10,
	}
	groupImage: string = ''
	groupSubTitle: string = ''
	groupSubtitleType: string = '' // text, status, number
	groupSubtitleText: string = ''
	notCompletedData: string = ''
}

export interface BusinessTypes {
	name: Name
	isActive: boolean
	id: number
}

export interface Countries {
	id: number
	name: Name
	isActive: boolean
	phoneCode: string
	position: {
		latitude: string
		longitude: string
	}
	isActivePhoneCode: boolean
	flagUrl: string
}

export interface Country {
	id: number
	name: Name
	flagUrl?: string
	phoneCode?: string
}

export interface workingHour {
	id?: number
	dayOfWeek: string
	isOn: boolean
	from: string
	to: string
	offTimeFrom: string
	offTimeTo: string
}

export class ImageUpload {
	imageCropDimensions = 1
	imageHeight: number = 500
	imageWidth: number = 1500
}

export interface Name {
	en: string
	ar: string
	fr?: string
}

export interface MobileNumber {
	phoneCode: string
	phoneCountryId: number
	countryCode: string
	mobileNumber: number
}

export interface Gallery {
	id: number
	url: string
	coverViewUrl: string
	isDefault: boolean
}
