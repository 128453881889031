import { Component, Inject, OnInit } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { TranslateService } from '@ngx-translate/core'
import { confirmModel } from '../../models/general.models'

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styles: [
    `
           .w-6 {
             width: 2rem !important;
           }
           .mat-mdc-icon-button {
             line-height: 33px;
           }
         `,
  ],
})
export class TermsConditionsComponent implements OnInit {
  lang: string = '';
  showShortDescription: boolean = true;
  constructor(
    public dialog: MatDialogRef<TermsConditionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: confirmModel,
    public translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.lang = this.translate.currentLang;
  }

  confirm() {
    this.closeDialog();
  }

  closeDialog() {
    this.dialog.close();
  }
}
